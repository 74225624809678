import { FunctionComponent, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link } from '@mui/material';

import { BRAND_COMPANY_NAME, LEGAL_NAME } from '../../../constants/branding';
import useConfirmation from '../../../hooks/useConfirmation';
import backend from '../../../lib/backend';
import routes from '../../../lib/routes';
import spinwheelActiveModal from '../../../state/atoms/spinwheelActiveModal';
import userStateNeedsReset from '../../../state/atoms/userStateNeedsReset';
import { useUser } from '../../../state/user';
import { REPAYMENT_MODAL_STEPS } from '../../../types/spinwheelModals';

const UserAgreementModal: FunctionComponent = () => {
  const setActiveModal = useSetRecoilState(spinwheelActiveModal);
  const { showConfirmationStep } = useConfirmation();
  const user = useUser();
  const [ termsAccepted, setTermsAccepted ] = useState(!!user.contents?.latestCustomerRepaymentProduct?.terms_accepted_date_time);
  const setUserStateResetNeeded = useSetRecoilState(userStateNeedsReset);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const onAgreement = useCallback(async () => {
    if (!user.contents.latestCustomerRepaymentProduct.terms_accepted_date_time) {
      await backend.updateTermsAcceptedDateTime(user.contents.latestCustomerRepaymentProduct.id);
      setUserStateResetNeeded(true); // refresh latest CRP data
    }

    // Close modal temporarily
    setActiveModal(null);

    showConfirmationStep({
      onClick: () => setActiveModal(REPAYMENT_MODAL_STEPS.LOAN_SERVICES_LOGIN),
      messages: {
        title: `Now Leaving ${BRAND_COMPANY_NAME}`,
        description: `You are leaving the ${LEGAL_NAME} website and are entering the website of a non-affiliated third party. ${LEGAL_NAME} is not responsible for the content of this site, and the ${LEGAL_NAME} Privacy Policy and Terms of Use do not apply to this site`,
      },
    });
  }, [ showConfirmationStep, user.contents.latestCustomerRepaymentProduct, setActiveModal, setUserStateResetNeeded ]);

  return (
    <Box id='user-agreement-dialog' sx={{ px: 3, py: 2 }}>
      <DialogTitle>User Agreement</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            (
              <Checkbox
                checked={termsAccepted}
                id='user-agreement-dialog-checkbox'
                onChange={handleCheckboxChange}
              />
            )
          }
          label={
            (
              <>
                Yes, I understand and agree to the&nbsp;
                <Link
                  href={routes.external.repaymentTermsFAQ.url}
                  target='_blank'
                >
                  <a>
                    Stride User Agreement
                  </a>
                </Link>
              </>
            )
          }
        />
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='space-evenly' width='100%'>
          <Button autoFocus disabled={!termsAccepted} id='user-agreement-dialog-confirm-action' onClick={() => onAgreement()} size='small' sx={{ m: 1 }} variant='contained'>
            Continue
          </Button>
          <Button id='user-agreement-dialog-cancel-action' onClick={() => setActiveModal(null)} size='small' sx={{ m: 1 }} variant='outlined'>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
};

export default UserAgreementModal;
